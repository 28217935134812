import { useState } from 'react'
import clipboard from 'copy-to-clipboard'


const useCopy = (text: string) => {
  const [ isCopied, setIsCopied ] = useState(false)

  const copy = () => {
    clipboard(text)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return { isCopied, copy }
}

export default useCopy
