import React, { useRef, useEffect, useCallback } from 'react'
import classnames from 'classnames'

import s from './NotificationBase.module.scss'


export type NotificationBaseProps = {
  closeNotification?: () => void
  onClick?: () => void
  type?: string
  preventAutoHide?: boolean
  autoHideSeconds?: number
}

const NotificationBase: React.FC<NotificationBaseProps> = (props) => {
  const { children, closeNotification, onClick, preventAutoHide, type = 'plain', autoHideSeconds = 10 } = props

  const nodeRef = useRef<HTMLDivElement>()

  const handleClose = useCallback(() => {
    nodeRef.current.style.marginTop = `${-1 * nodeRef.current.clientHeight}px`
    nodeRef.current.classList.add(s.closed)
    setTimeout(closeNotification, 300)
  }, [ closeNotification ])

  useEffect(() => {
    if (preventAutoHide) {
      return
    }

    const timer = setTimeout(handleClose, autoHideSeconds * 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [ handleClose ])

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick()
    }
  }, [ handleClose, onClick ])

  const classNames = classnames(s.notification, { [s.error]: type === 'error' })

  return (
    <div
      ref={nodeRef}
      className={classNames}
      role="alert"
      data-testid="notification"
      onClick={handleClick}
    >
      {children}
    </div>
  )
}


export default NotificationBase
