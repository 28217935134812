import { Contract } from '@ethersproject/contracts'
import type { ContractInterface } from '@ethersproject/contracts'
import type { JsonRpcProvider } from '@ethersproject/providers'

import babylon8coreAbi from './abis/babylon8Core.json'
import erc721Abi from './abis/ERC721.json'
import erc1155Abi from './abis/ERC1155.json'
import randomProviderAbi from './abis/randomProvider.json'

import type { Babylon8Core, ERC721, ERC1155, RandomProvider } from './types'


export const infuraKey = process.env.NEXT_PUBLIC_INFURA_KEY
export const alchemyKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY

export type Contracts = {
  babylon8Core: Babylon8Core
  randomProvider: RandomProvider
}

type ContractName = keyof Contracts
type Addresses = Record<ContractName, string>

export const addressesByChains = {
  1: JSON.parse(process.env.NEXT_PUBLIC_CONTRACTS_1 || null),
  11155111: JSON.parse(process.env.NEXT_PUBLIC_CONTRACTS_11155111 || null),
}

export const abis: Record<ContractName, ContractInterface> = {
  babylon8Core: babylon8coreAbi,
  randomProvider: randomProviderAbi,
}

export const getCustomProps = ({ addresses, provider }: { addresses: Addresses, provider: JsonRpcProvider }) => ({
  provider,

  getERC721Contract: (address: string) => {
    return new Contract(address, erc721Abi, provider) as ERC721
  },

  getERC1155Contract: (address: string) => {
    return new Contract(address, erc1155Abi, provider) as ERC1155
  },
})
