import { openNotification } from '@locmod/notifications'
import { openCreateAccountModal } from 'compositions/modals/CreateAccountModal/CreateAccountModal'
import { constants, jwt, localStorage, restAPI, signMessage } from 'helpers'
import { createContext, useContext, useState, useEffect } from 'react'
import { useSWRConfig } from 'swr'
import { useConnect } from 'web3'


type ProfileResult = {
  data: Profile
  error: boolean
  error_message: string
}

type ProfileRequired = Pick<Profile, 'address' | 'email' | 'display_name'>

 type ChangeAvatarProps = {
   imgUrl: string
   file: File
 }
 type SetAvatarProps = AtLeastOne<ChangeAvatarProps, 'imgUrl' | 'file'>

type State = {
  isFetching: boolean
  profile: Profile
  notifications: ServerNotification[]
  isExisting: boolean
  refetch: () => Promise<void>
  isSubmitting: boolean
  edit: (profile: Partial<Profile>) => Promise<void>
  editSocials: (profile: Pick<Profile, 'address' | 'links'>) => Promise<void>
  create: (profile: ProfileRequired) => Promise<void>
  sendVerificationEmail: () => Promise<void>
  confirmEmail: (token: string) => Promise<void>
  getToken: () => Promise<string>
  setAvatar: (props: SetAvatarProps) => Promise<void>
  markNotificationAsSeen: (notificationId: number) => void
}

const Context = createContext<State>(null)

export const useProfile = () => useContext(Context)

export const ProfileProvider = ({ children }) => {
  const { account, library } = useConnect()
  const [ isFetching, setIsFetching ] = useState(true)
  const [ profile, setProfile ] = useState<Profile>()
  const [ isExisting, setIsExisting ] = useState<boolean>()
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const { mutate: globalMutate } = useSWRConfig()
  const [ notifications, setNotifications ] = useState<ServerNotification[]>([])

  const fetch = async () => {
    setIsFetching(true)

    if (!account) {
      return
    }

    let { token, isExpired, isAccount } = jwt()

    const config = {
      headers: {
        jwt: token,
      },
      timeout: 5000,
    }

    const isTokenValid = !!token && !isExpired && isAccount(account)

    try {
      const { data } = await restAPI.get<ProfileResult>(`/user?address=${account}`, isTokenValid ? config : undefined)

      if (!data.error) {
        setProfile(data.data)
        setIsExisting(true)
        setIsFetching(false)
      }
    }
    catch (error) {
      console.log(error.message)

      if (error?.response?.data?.error_message?.includes('not found')) {
        setIsExisting(false)
        setProfile(undefined)

        const skipped = localStorage.getItem(constants.localStorage.skippedRegistration)

        if (!skipped) {
          openCreateAccountModal()
        }
      }

      setIsFetching(false)

      return
    }
  }

  const getToken = async () => {
    setIsSubmitting(true)
    try {
      let { token, isExpired, isAccount } = jwt()

      if (!isExpired && token && isAccount(account)) {
        return token
      }

      const { timestamp, signature } = await signMessage(library, account)

      const bodyFormData = new FormData()
      bodyFormData.set('address', account)
      bodyFormData.set('signature', signature)
      bodyFormData.set('timestamp', String(timestamp))

      const { data } = await restAPI.post('/auth', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })

      token = data.data.token as string
      localStorage.setItem(constants.localStorage.token, token)

      setIsSubmitting(false)

      return token
    }
    catch (error) {
      setIsSubmitting(false)

      if (error?.response?.data?.error_message === 'invalid token') {
        throw new Error('Could not verify your signature. Try again')
      }
      else if (error?.response?.data?.error_message === 'invalid timestamp') {
        throw new Error('Your system time seems to be incorrect. <br /><br /> <a href="https://www.technewstoday.com/set-time-and-date-automatically/" target="_blank">Learn how to fix this</a>')
      }
      else if (error.code === -32603) {
        throw new Error('You denied the signature')
      } {
        throw new Error(error)
      }
    }
  }

  const edit = async (profile: Partial<Omit<Profile, 'links'>>) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = {
        address: account,
        ...profile,
      }

      await restAPI.post('/user', formData, config)
      fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      openNotification('plain', {
        error: true,
        title: 'Couldn\'t save profile',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const editSocials = async (profile: Pick<Profile, 'address' | 'links'>) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      await restAPI.post('/user/links', profile, config)
      fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      openNotification('plain', {
        error: true,
        title: 'Couldn\'t save profile socials',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const create = async (profile: ProfileRequired) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = {
        address: account,
        ...profile,
      }

      await restAPI.post('/user', formData, config)
      await fetch()
      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'

      openNotification('plain', {
        error: true,
        title: 'Couldn\'t create profile',
        text: errorMessage,
      })
      setIsSubmitting(false)
      throw new Error('Couldn\'t create profile')
    }
  }

  const sendVerificationEmail = async () => {
    setIsSubmitting(true)
    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
      }

      await restAPI.post('/mail/send-confirmation', {}, config)

      setIsSubmitting(false)
    }
    catch (error) {
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      openNotification('plain', {
        error: true,
        title: 'Couldn\'t send confirmation',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const setAvatar = async ({ imgUrl, file }: SetAvatarProps) => {
    setIsSubmitting(true)

    try {
      const token = await getToken()

      if (!token) {
        return
      }

      const config = {
        headers: {
          jwt: token,
        },
        timeout: 5000,
      }

      const formData = new FormData()
      formData.append('address', account)

      if (file) {
        formData.append('avatar', file)
      }

      if (imgUrl) {
        formData.append('link', imgUrl)
      }

      await restAPI.post('/user/avatar', formData, config)
      await fetch()
      const key = `/user?address=${account}`

      globalMutate(key)
      setIsSubmitting(false)
    }
    catch (error) {
      console.log(error)
      const errorMessage = error?.response?.data?.error_message || error.message || 'Unknown error happened'
      openNotification('plain', {
        error: true,
        title: 'Couldn\'t set profile picture',
        text: errorMessage,
      })
      setIsSubmitting(false)
    }
  }

  const confirmEmail = async (token: string) => {
    try {
      setIsSubmitting(true)
      await restAPI.post(`/mail/confirm?token=${token}`)
      await fetch()
      setIsSubmitting(false)
    }
    catch (err) {
      setIsSubmitting(false)
      throw new Error('Error confirming email')
    }
  }

  const fetchNotifications = async () => {
    type NotificationsResult = {
      data: {
        notifications: ServerNotification[]
        total_count: number
        non_seen: number
      }
      error: boolean
      error_message: string
    }

    const { data } = await restAPI.get<NotificationsResult>(`notifications/${account}`)

    if (Array.isArray(data.data?.notifications)) {
      setNotifications(data.data.notifications)
    }
  }

  const markNotificationAsSeen = async (notificationId: number) => {
    await restAPI.post(`/notifications/${account}/${notificationId}`)

    fetchNotifications()
  }

  useEffect(() => {
    if (account) {
      fetchNotifications()
      const intervalId = setInterval(fetchNotifications, 60 * 1000 * 5) // every 5 minutes

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [ account ])

  const state = {
    isFetching,
    profile,
    isExisting,
    refetch: fetch,
    isSubmitting,
    notifications,
    edit,
    create,
    sendVerificationEmail,
    confirmEmail,
    getToken,
    setAvatar,
    editSocials,
    fetchNotifications,
    markNotificationAsSeen,
  }

  useEffect(() => {
    if (account) {
      fetch()
    }
  }, [ account ])

  function parseBinaryJsonNotification(blob: Blob): Promise<ServerNotification> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const text = reader.result as string
        resolve(JSON.parse(text))
      }
      reader.onerror = reject
      reader.readAsText(blob)
    })
  }

  function connectWebSocket(account: string) {
    const url = `${process.env.NEXT_PUBLIC_API_URL.replace('http', 'ws')}api/v1/notifications/upgrade/${account}`
    const ws = new WebSocket(url)

    // Handle WebSocket connection open event
    ws.onopen = () => {
      // console.log('ws connected')
    }

    // Handle WebSocket message event
    ws.onmessage = (event) => {
      parseBinaryJsonNotification(event.data)
        .then((notification) => {
          setNotifications((prev) => [ notification, ...prev ])
        }).catch(error =>
          console.log(error)
        )
    }

    // Handle WebSocket connection close event
    ws.onclose = () => {
      // console.log('ws closed')

      setTimeout(() => {
        connectWebSocket(account)
      }, 1000)
    }

    return ws
  }

  useEffect(() => {
    if (!account) {
      return
    }

    const ws = connectWebSocket(account)

    return () => {
      ws.close()
    }
  }, [ account ])


  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  )
}
