import React, { useCallback } from 'react'
import type { Field } from 'formular'
import { useFieldState } from 'formular'
import cx from 'classnames'

import s from './Checkbox.module.scss'


export type CheckboxBaseProps = {
  className?: string
  value?: boolean
  defaultValue: any
  disabled?: boolean
  fullWidth?: boolean
  onChange: (value: any) => void
  dataTestId?: string
}

export const CheckboxBase: React.FC<CheckboxBaseProps> = (props) => {
  const { children, className, value, defaultValue, disabled, fullWidth, onChange, dataTestId } = props

  const isCheckboxGroup = Array.isArray(value)
  const isChecked = defaultValue || value

  const rootClassName = cx(s.root, className, {
    [s.disabled]: disabled,
    [s.on]: isChecked,
    'w-full': fullWidth,
  })

  const handleClick = () => {
    // checkbox group
    if (isCheckboxGroup) {
      if (value.includes(defaultValue)) {
        onChange(value.filter((v) => v !== defaultValue))
      }
      else {
        onChange([ ...value, defaultValue ])
      }
    }
    // single checkbox
    else {
      onChange(!value)
    }
  }

  return (
    <>
      <div onClick={handleClick}>
        {children}
      </div>
      <div
        className={rootClassName}
        data-testid={dataTestId}
        onClick={handleClick}
      >
        <div className={s.switch} />
      </div>
    </>
  )
}

export type CheckboxProps = {
  className?: string
  field: Field<any>
  value?: boolean
  disabled?: boolean
  fullWidth?: boolean
  dataTestId?: string
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
  const { children, className, field, value, disabled, fullWidth } = props

  const state = useFieldState(field)

  const rootClassName = cx(
    className
  )

  const handleChange = useCallback((value) => {
    field.set(value)
  }, [ field ])

  return (
    <CheckboxBase
      className={rootClassName}
      value={state.value}
      defaultValue={value}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={handleChange}
    >
      {children}
    </CheckboxBase>
  )
}


export default Checkbox
