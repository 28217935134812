import { openNotification } from '@locmod/notifications'
import { useProfile } from 'context'
import { useForm } from 'formular'
import { jwt, restAPI } from 'helpers'
import { required, validateEmail, upTo10Symbols } from 'helpers/validators'
import { useEffect } from 'react'
import { useConnect } from 'web3'


export type CreateForm = {
  display_name: string
  email: string
}

const useCreateAccount = () => {
  const { profile, create, isSubmitting, getToken } = useProfile()
  const { account } = useConnect()

  const form = useForm<CreateForm>({
    fields: {
      display_name: [ required, upTo10Symbols ],
      email: [ required, validateEmail ],
    },
  })

  const sendAccount = async () => {
    try {
      await restAPI.post('/user/add', { address: account })
    }
    catch (error) {
      console.error(error)
    }
  }

  const createProfile = async () => {
    const { values, errors } = await form.submit()

    if (errors) {
      openNotification('plain', { error: true, title: 'Error', text: 'Please, fill the form' })

      return
    }

    const { token, isExpired } = jwt()

    try {
      if (!token || isExpired) {
        await getToken()
      }

      create({ address: account, ...values })
    }
    catch (error) {
      openNotification('plain', { error: true, title: 'Error', text: error.message })
    }
  }

  useEffect(() => {
    sendAccount()
  }, [])

  return {
    form,
    isSubmitting,
    createProfile,
    sendAccount,
    isCreated: Boolean(profile),
  }
}

export default useCreateAccount
