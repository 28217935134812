import React from 'react'
import cx from 'classnames'
import { ActiveLink } from 'components/navigation'
import { Text } from 'components/dataDisplay'
import { navItems } from '../navItems'
import s from './Navbar.module.scss'


type NavbarProps = {
  onNavItemClick?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onNavItemClick }) => {
  return (
    <div className={cx(s.navbar, 'flex gap-24')}>
      {
        navItems.map(item => (
          <ActiveLink href={item.href} key={item.href} activeClassName={s.active}>
            <a
              className={cx(s.item, 'cursor-pointer')}
              onClick={onNavItemClick}
              target={item.inTab ? '_blank' : undefined}
              title={item.title}
            >
              <Text message={item.title} style="glitch18" />
            </a>
          </ActiveLink>
        ))
      }
    </div>
  )
}

export default Navbar
