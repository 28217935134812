import React, { FC, useState } from 'react'
import { modalVisibility, openModal, closeModal } from '@locmod/modal'
import type { ModalVisibilityProps } from '@locmod/modal'
import { PlainModal } from 'components/feedback'
import { Text } from 'components/dataDisplay'
import s from './ConnectWalletModal.module.scss'
import { ConnectorNames, useConnect } from 'web3'
import { constants } from 'helpers'
import { Icon } from 'components/ui'


export enum Wallet {
  Metamask = 'metamask',
  WalletConnect = 'walletconnect'
}

export type ConnectorsByName = {
  [connectorName in ConnectorNames]: {
    wallet: Wallet
    title: string
    connector: ConnectorNames
  }
}

const connectorsByName: ConnectorsByName = {
  [ConnectorNames.Injected]: {
    wallet: Wallet.Metamask,
    title: 'MetaMask',
    connector: ConnectorNames.Injected,
  },
  [ConnectorNames.WalletConnect]: {
    wallet: Wallet.WalletConnect,
    title: 'WalletConnect',
    connector: ConnectorNames.WalletConnect,
  },
}

type WalletButtonProps = {
  title: string
  connector: ConnectorNames
  wallet: Wallet
  onClick: (connector: ConnectorNames) => void
}

const WalletButton: FC<WalletButtonProps> = ({ title, connector, wallet, onClick }) => {
  return (
    <div className={s.walletBtn} onClick={() => onClick(connector)}>
      <img width={24} height={24} src={`/images/icons/wallets/${wallet}.svg`} alt={wallet} />
      <Text className="mt-12" style="c16" message={title} />
    </div>
  )
}

const ConnectWalletModal: React.FC<ModalVisibilityProps> = (props) => {
  const { connect } = useConnect()
  const [ isConnecting, setIsConecting ] = useState<boolean>(false)

  const { closeModal } = props

  const handleConnect = async (connector: ConnectorNames) => {
    if (isConnecting) {
      return
    }

    setIsConecting(true)

    try {
      await connect(connector)
      setIsConecting(false)
      closeModal()
    }
    catch (err) {
      if (err.message.includes('No Ethereum provider was found')) {
        const newWindow = window.open('https://metamask.io/download/', '_blank', 'noopener,noreferrer')

        if (newWindow) {
          newWindow.opener = null
        }
      }

      setIsConecting(false)
    }
  }

  return (
    <PlainModal
      closeModal={closeModal}
      className={s.modal}
    >
      <div className="mt-24 text-center">
        {
          isConnecting ? (
            <>
              <Text style="h6" message="WAITING FOR CONFIRMATION" />
              <Text
                className="mt-8"
                style="c14"
                html
                message="Open Metamask extension to confirm <br /> your connection to our website"
                color="gray-60"
              />
              <Icon className="my-24" name="interface/spinner" size={48} />
            </>
          ) : (
            <>
              <Text style="h6" message="Connect Wallet" />
              <div className={s.container}>
                <div className="mt-24 flex gap-8">
                  {
                    Object.keys(connectorsByName).map((name) => {
                      const { wallet, title, connector } = connectorsByName[name]

                      return (
                        <WalletButton
                          key={title}
                          wallet={wallet}
                          title={title}
                          connector={connector}
                          onClick={handleConnect}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        }
        <div className={s.divider} />
        <div className="p-12">
          <Text className="uppercase" style="c12" message="By connecting a wallet, you agree to" color="gray-40" />
          <a href={constants.urls.termsOfService} target="_blank" rel="noreferrer">
            <Text className="mt-4 uppercase hover:underline cursor-pointer" style="c12" message="babylon.art Terms & Conditions" color="gray-60" />
          </a>
        </div>
      </div>
    </PlainModal>
  )
}

export const openConnectWalletModal = () => openModal('ConnectWalletModal')
export const closeConnectWalletModal = () => closeModal('ConnectWalletModal')

export default modalVisibility('ConnectWalletModal', ConnectWalletModal)
