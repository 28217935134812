import React from 'react'
import { WidthContainer } from 'components/layout'
import s from './Footer.module.scss'
import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import { Icon, IconName } from 'components/ui'
import { constants } from 'helpers'
import ScrambledText from 'components/dataDisplay/ScrambledText/ScrambledText'
import cx from 'classnames'
import { useDevice } from 'device'


export const otherItems = [
  { title: '< Privacy Policy />', href: constants.urls.privacyPolicy },
  { title: '< Terms of Service />', href: constants.urls.termsOfService },
  { title: '< Community & Support />', href: constants.urls.support },
]

const SocialLink: React.FC<{ icon: IconName, href: string }> = ({ icon, href }) => {
  return (
    <Button href={href} target="_blank" style="white" size={32}>
      <Icon name={icon} size={24} color="gray-5" />
    </Button>
  )
}

const FooterLink: React.FC<{ url: string, title: string }> = ({ children, url, title }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" title={title}>
      {children}
    </a>
  )
}

const Footer: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <footer className={s.bg}>
      <WidthContainer className={cx(s.content, 'flex flex-col items-center')}>
        <img className={cx(s.logo, isMobile ? 'mb-48' : 'mb-64')} src="/images/logos/bg-logo.webp" alt="Babylon 7" />
        <div className={isMobile ? 'flex gap-12 mb-24' : 'flex gap-12 mb-40'}>
          <SocialLink icon="footer/twitter" href={constants.urls.twitter} />
          <SocialLink icon="footer/medium" href={constants.urls.medium} />
          {/* <SocialLink icon="footer/instagram" href={constants.urls.instagram} /> */}
        </div>
        <div className={isMobile ? 'flex gap-12 mb-48 flex-col items-center' : 'flex gap-40 mb-64'}>
          {
            otherItems.map(item => (
              <FooterLink key={item.href} url={item.href} title={item.title}>
                <ScrambledText className={s.link} style="c16" message={item.title} color="gray-60" />
              </FooterLink>
            ))
          }
        </div>
        <Text className="mb-12" style="u14" message="Babylon 7 2023. All rights reserved" color="gray-40" />
        <div className="flex gap-8">
          <Text style="u14" message="Design by" color="gray-40" />
          <a href={constants.urls.designBy} target="_blank" rel="noreferrer">
            <Text className={s.link} style="c14" message="Flatstudio" color="gray-60" />
          </a>
        </div>
      </WidthContainer>
    </footer>
  )
}

export default Footer
