import React, { FC, useState } from 'react'
import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'
import { jwt, shortenAddress } from 'helpers'
import { useConnect } from 'web3'
import s from './AccountDropdown.module.scss'
import copy from 'copy-to-clipboard'
import { toLocaleString } from 'helpers/formatters'
import { useBalance, useCopy, useScanAddress } from 'hooks'
import { useRouter } from 'next/router'
import { useDevice } from 'device'
import MenuButton from './MenuButton/MenuButton'


const AccountDropdown: FC = () => {
  const { account, disconnect } = useConnect()
  const router = useRouter()
  const { isBalanceFetching, balance } = useBalance()
  const url = useScanAddress(account)
  const { isAccount, isAdmin } = jwt()
  const { isMobile } = useDevice()
  const { isCopied, copy } = useCopy(account)

  const showAdmin = isAccount(account) && isAdmin

  const handleDisconnect = () => {
    disconnect()
    router.push('/')
  }

  return (
    <div className={s.container}>
      <div className={s.accountInfo}>
        <div className="flex justify-between items-center">
          <Text style="c12" message={shortenAddress(account)} />
          <div className="flex gap-12">
            <div className={s.icon} onClick={copy}>
              <Icon name={!isCopied ? 'interface/copy' : 'interface/check'} size={16} />
            </div>
            <a className={s.icon} href={url} target="_blank" rel="noreferrer">
              <Icon name={'interface/external-link'} size={16} />
            </a>
          </div>
        </div>
        <div className={s.divider} />
        <div className="flex items-center justify-between">
          <Text style="c12" message="Balance" />
          {
            isBalanceFetching ? (
              <div className="flex items-center">
                <Icon name="interface/spinner" size={isMobile ? 12 : 14} />
                <Text className="ml-4" style="c12" message={'ETH'} />
              </div>
            ) : (
              <Text style="c12" message={toLocaleString(balance, { currency: 'ETH' })} />
            )
          }
        </div>
      </div>
      <div>
        <MenuButton
          icon="interface/plus"
          title="Create a raffle"
          to="/create"
        />
        <MenuButton
          icon="interface/person"
          title="My profile"
          to={`/user/${account}`}
        />
        <MenuButton
          icon="interface/edit"
          title="Edit profile"
          to="/profile/edit"
        />
        {
          showAdmin && (
            <MenuButton
              icon="interface/settings"
              title="admin panel"
              to="/admin"
            />
          )
        }
        <div className={s.divider} />
        <MenuButton
          icon="interface/disconnect"
          title="Disconnect wallet"
          onClick={handleDisconnect}
        />
      </div>
    </div>
  )
}

export default AccountDropdown
