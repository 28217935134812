import React, { FC, useState, useEffect } from 'react'
import { Text } from 'components/dataDisplay'
import { constants, localStorage } from 'helpers'
import cx from 'classnames'
import s from './PrivacyPolicyWarning.module.scss'
import { Icon } from 'components/ui'
import { useDevice } from 'device'
import { Button } from 'components/inputs'


const PrivacyPolicyWarning: FC = () => {
  const [ isAccepted, setAccepted ] = useState(true)
  const { isMobile } = useDevice()

  useEffect(() => {
    setAccepted(localStorage.getItem<boolean>(constants.localStorage.policyConcent, false))
  }, [])

  if (isAccepted) {
    return null
  }

  const handleAccept = () => {
    localStorage.setItem(constants.localStorage.policyConcent, true)
    setAccepted(true)
  }

  return (
    <div className={cx(s.warning, s.visible)}>
      <div className={s.glitch} />
      <div className={isMobile ? 'flex flex-col' : 'flex'}>
        <div className="flex">
          <Icon className={s.icon} name="interface/cookie" size={40} />
          <Text
            className="ml-12"
            style="glitch18"
            message={'[ We employ cookies to ensure a seamless user experience. Stay comfortable, stay connected. ]'}
            html
          />
        </div>
        <div className={isMobile ? 'flex justify-between items-end mt-12' : ''}>
          {
            isMobile && (
              <Text
                className="ml-12"
                style="glitch18"
                color="gray-40"
                html
                message={'7.babylon.art<br/ >st$tus: re%u\ld/ng..'}
              />
            )
          }
          <Button
            className={s.okBtn}
            title="ok"
            style="white"
            size={40}
            onClick={handleAccept}
          />
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyWarning
