import React, { forwardRef, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useUniqueId } from 'hooks'
import { useDevice } from 'device'
import { Text } from 'components/dataDisplay'

import createTooltip from './util/createTooltip'
import s from './Tooltip.module.scss'


export type TooltipProps = {
  title?: string
  text?: string
}

type TooltipRefProps = TooltipProps & {
  id: string
}

const Tooltip = forwardRef<HTMLDivElement, TooltipRefProps>(({ id, title, text }, ref) => createPortal(
  <div ref={ref} id={id} className={s.tooltip} role="tooltip">
    {title && <Text style="c16" message={title} className="mb-12" />}
    <Text style="c12" message={text} color="gray-60" />
  </div>,
  document.getElementById('tooltips')
))

const TooltipWrapper = ({ children, title, text }: { children: React.ReactChild } & TooltipProps) => {
  const { isMobile } = useDevice()

  const id = useUniqueId('tooltip')
  const triggerRef = useRef<HTMLElement>()
  const tooltipRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!triggerRef.current) {
      console.error('triggerRef for Tooltip is undefined', {
        extra: {
          children,
          text,
        },
      })

      return
    }

    return createTooltip({
      trigger: triggerRef.current,
      tooltip: tooltipRef.current,
      styles: s,
      isMobile,
    })
  }, [ children, text, isMobile ])

  return (
    <>
      <Tooltip ref={tooltipRef} id={id} title={title} text={text} />
      {
        // @ts-ignore
        React.cloneElement(children, {
          ref: triggerRef,
          'aria-describedby': id,
        })
      }
    </>
  )
}


export default React.memo(TooltipWrapper)
