import type { Nft } from 'alchemy-sdk'
import fetchNftJson from './fetchNftJson'
import getIpfsUrl from './getIpfsUrl'


export const parseNftInfo = async (nft: Nft, owner: string): Promise<NFT> => {
  let mediaUrl: string = getIpfsUrl(nft?.media?.[0]?.raw || nft.media?.[0]?.gateway)
  let title: string = nft?.title || nft?.contract?.name + ' #' + nft?.tokenId

  if (nft.metadataError) {
    try {
      const url = getIpfsUrl(nft?.tokenUri?.gateway || nft?.tokenUri?.raw)
      const parsed = await fetchNftJson(url)

      mediaUrl = getIpfsUrl(parsed?.image)
      title = parsed.name
    }
    catch (error) {
      // console.log('error fetching NFT', nft)
    }
  }

  return {
    tokenAddress: nft?.contract?.address,
    tokenId: nft?.tokenId,
    tokenType: nft?.tokenType,
    mediaUrl,
    title,
    owner,
    contractName: nft?.contract?.name,
  }
}
