import React, { createContext, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useFreezeBodyScroll, useUniqueId } from 'hooks'
import TrapFocus from '@locmod/trap-focus'
import cx from 'classnames'

import { Overlay } from 'components/layout'
import { IconButton } from 'components/inputs'

import s from './PlainModal.module.scss'


export const ModalContext = createContext(null)

export type PlainModalProps = {
  className?: string
  contentClassName?: string
  containerClassName?: string
  overlayClosable?: boolean
  withCloseButton?: boolean
  closeModal: (withOnClose?: boolean) => void
  dataTestId?: string
}

const PlainModal: React.FunctionComponent<PlainModalProps> = (props) => {
  const { children, className, overlayClosable = true, withCloseButton = true, closeModal, dataTestId, contentClassName, containerClassName } = props

  const uniqueId = useUniqueId()
  const trapFocusId = `trapfocus_${uniqueId}`

  useFreezeBodyScroll()

  useEffect(() => {
    const trapFocusElement = document.getElementById(trapFocusId)

    // could be "null" if modal opened in iframe
    if (trapFocusElement) {
      const trapFocus = new TrapFocus(trapFocusElement, { withInitialFocus: false })

      trapFocusElement.focus()
      trapFocus.mount()

      return () => {
        trapFocus.unmount()
      }
    }
  }, [ trapFocusId ])

  const handleOverlayClick = useCallback((event) => {
    event.stopPropagation()

    if (overlayClosable) {
      closeModal(true)
    }
  }, [ overlayClosable, closeModal ])

  const handleCloseButtonClick = useCallback(() => {
    closeModal(true)
  }, [ closeModal ])

  const handleModalClick = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const rootClassName = cx(s.container, containerClassName)
  const modalClassName = cx(s.plainModal, className)
  const contentWrapperClassName = cx(s.content, contentClassName)

  useEffect(() => {
    const handleKeyDown = (event) => {
      const escapeKeyCode = 27

      if (event.keyCode === escapeKeyCode) {
        closeModal(true)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return createPortal(
    <Overlay className={s.overlay} onClick={handleOverlayClick}>
      <div className={rootClassName} data-testid={dataTestId}>
        <div
          id={trapFocusId}
          tabIndex={0}
          role="dialog"
          aria-modal="true"
          className={modalClassName}
          onClick={handleModalClick}
        >
          {
            withCloseButton && (
              <IconButton
                className={s.closeButton}
                icon="interface/close"
                size={20}
                onClick={handleCloseButtonClick}
              />
            )
          }
          <div className={contentWrapperClassName}>
            {children}
            <div className={s.glitch} />
          </div>
        </div>
      </div>
    </Overlay>,
    document.getElementById('modals')
  )
}


export default PlainModal
