import React from 'react'
import cx from 'classnames'
import s from './Avatar.module.scss'
import { Icon } from 'components/ui'
import InlineSvg from 'components/ui/InlineSvg/InlineSvg'


const sizes = [ 14, 18, 24, 28, 32, 40, 48, 116, 164, 180 ] as const

export type AvatarSize = typeof sizes[number]

type AvatarProps = {
  className?: string
  src?: string
  address?: string
  size: AvatarSize
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, src, address, size } = props

  const sizeClassName = s[`size-${size}`]
  const rootClassName = cx(s.avatar, className, sizeClassName)

  if (!src && !address) {
    return null
  }

  return (
    <div className={rootClassName}>
      <div className={cx(s.inner)}>
        {
          src ? (
            <>
              <InlineSvg className={s.border} src="/images/border.svg" />
              <img
                className={s.image}
                loading="lazy"
                src={src}
                alt="profile picture"
              />
            </>
          ) : (
            <>
              <InlineSvg className={s.border} src="/images/border.svg" />
              <Icon name="interface/person" size={size} />
            </>
          )
        }
      </div>
    </div>
  )
}

export default Avatar
