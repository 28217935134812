import { useProfile } from 'context'
import React, { FC, useEffect } from 'react'
import s from './NotificationsDropdown.module.scss'
import NotificationCard from './NotificationCard/NotificationCard'
import { Text } from 'components/dataDisplay'
import { IconButton } from 'components/inputs'
import { useDevice } from 'device'
import InlineSvg from 'components/ui/InlineSvg/InlineSvg'
import { event } from 'helpers'


type NotificationsDropdownProps = {
  unseenCount: number
}

const NotificationsDropdown: FC<NotificationsDropdownProps> = ({ unseenCount }) => {
  const { notifications } = useProfile()
  const { isMobile } = useDevice()

  useEffect(() => {
    event('notification_profile', {
      action: 'view',
    })
  }, [])

  return (
    <div className={s.container}>
      <div className={s.title}>
        <Text style="c14" message={`Notifications [${unseenCount}]`} />
        {
          !isMobile && (
            <IconButton
              icon="interface/close"
              size={20}
            />
          )
        }
      </div>
      {
        notifications.map(notification => <NotificationCard key={notification.id} notification={notification} />)
      }
      {
        Boolean(notifications && !notifications.length) && (
          <div className="flex flex-col justify-center items-center text-center my-24">
            <InlineSvg src="/images/sad.svg" />
            <Text className="mt-32" message={'{ Empty }'} style="h6" />
            <Text className="mt-12" message={'[ You don\'t have any notifications yet ]'} style="c14" color="gray-60" />
          </div>
        )
      }
    </div>
  )
}

export default NotificationsDropdown
