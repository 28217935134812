import React, { FC } from 'react'
import { Text } from 'components/dataDisplay'
import { Input } from 'components/forms'
import { Button } from 'components/inputs'
import { Form } from 'formular'
import { CreateForm } from '../../utils/useCreateAccount'
import { constants, localStorage } from 'helpers'


type FormProps = {
  closeModal: () => void
  create: () => void
  form: Form<CreateForm>
}

const FormComponent: FC<FormProps> = ({ form, create, closeModal }) => {

  const handleSkip = () => {
    localStorage.setItem(constants.localStorage.skippedRegistration, true)
    closeModal()
  }

  return (
    <>
      <Text style="h6" message="Set up your profile" />
      <Text
        className="mt-8"
        style="c14"
        html
        message="To complete your profile creation, we need to learn a bit more about you. Please provide us with additional information"
        color="gray-60"
      />
      <Input className="mt-12" placeholder="Name" field={form.fields.display_name} />
      <Input className="mt-12" placeholder="Email" field={form.fields.email} />
      <Button
        className="mt-24"
        title="+ Create profile +"
        style="white"
        fullWidth
        onClick={create}
      />
      <Button
        className="mt-12"
        title="Do it later"
        size={48}
        fullWidth
        onClick={handleSkip}
      />
    </>
  )
}

export default FormComponent
