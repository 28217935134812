import React from 'react'
import type { AppProps } from 'next/app'
import { TxProvider } from '@locmod/active-transactions'
import { Web3Provider, Connector } from 'web3'
import { ChainIdProvider } from 'chain-id'
import { ContractsProvider } from 'contracts/client'
import { SWRConfig } from 'swr'
import type { SWRConfiguration } from 'swr'

import 'dayjs/locale/en'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import Notifications from 'components/notifications/Notifications'
import PageLayout from 'compositions/PageLayout/PageLayout'
import Meta from 'compositions/Meta/Meta'
import { ghostCursor } from 'compositions/GhostCursor/GhostCursor'

import '../scss/sanitize.scss'
import '../scss/root.scss'
import '../scss/globals.scss'
import { ProfileProvider } from 'src/context/Profile'


dayjs.extend(utc)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.locale('en')

// https://swr.vercel.app/docs/options#options
const swrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
}

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  let content = (
    <PageLayout>
      <Component {...pageProps} />
    </PageLayout>
  )

  return (
    <Web3Provider>
      <ChainIdProvider>
        <ContractsProvider>
          <Connector>
            <TxProvider>
              <SWRConfig value={swrConfig}>
                <ProfileProvider>
                  <Meta />
                  {content}
                </ProfileProvider>
              </SWRConfig>
              <Notifications />
            </TxProvider>
          </Connector>
        </ContractsProvider>
      </ChainIdProvider>
    </Web3Provider>
  )
}

export default MyApp
