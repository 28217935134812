import { shortenAddress } from 'helpers'
import React, { FC } from 'react'
import { useScanAddress } from 'hooks'
import Text from '../Text/Text'
import s from './AddressLink.module.scss'
import { Bone } from 'components/feedback'
import cx from 'classnames'
import { Icon } from 'components/ui'


type AddressLinkProps = {
  className?: string
  address: Address
  isFetching?: boolean
}

const AddressLink: FC<AddressLinkProps> = ({ className, isFetching, address }) => {
  const url = useScanAddress(address)

  if (isFetching) {
    return (
      <div className={s.url}>
        <Bone h={18} w={100} className="radius-8" />
      </div>
    )
  }

  if (!address) {
    return null
  }

  return (
    <a className={cx(s.url, className)} href={url} target="_blank" rel="noreferrer">
      <Text style="c14" message={shortenAddress(address)} color="gray-100" />
      <Icon className="ml-4" name="interface/external-link" size={16} />
    </a>
  )
}

export default AddressLink
