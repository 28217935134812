import React, { FC } from 'react'
import s from './GlitchImage.module.scss'
import cx from 'classnames'


const GlitchImage: FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  const { className, src, alt, ...restProps } = props

  return (
    <div className={cx(s.container, className)}>
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
      <img className={s.glitchImage} src={src} alt={alt} {...restProps} />
    </div>
  )
}

export default GlitchImage
