import React from 'react'
import cx from 'classnames'

import TextProxy from './TextProxy'

import s from './Text.module.scss'


export const stylesMap = {
  header: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ],
  glitch: [ 'glitch24', 'glitch18' ],
  paragraph: [ 'sub', 'u16', 'u14', 'u12', 'p1', 'c16', 'c14', 'c12' ],
  button: [ 'button-l', 'button-s' ],
} as const

export const styles = [
  ...stylesMap.header,
  ...stylesMap.paragraph,
  ...stylesMap.glitch,
  ...stylesMap.button,
] as const

export const colors = [
  'inherit',
  'yellow',
  'accent-red-60',
  'accent-red-50',
  'accent-red-10',
  'accent-green-60',
  'accent-green-50',
  'accent-green-10',
  'gray-5',
  'gray-10',
  'gray-20',
  'gray-40',
  'gray-60',
  'gray-80',
  'gray-100',
  'bg-1',
  'bg-2',
  'bg-3',
] as const

export const gradients = [
  'raffle',
] as const

export const aligns = [ 'left', 'center', 'right' ] as const

export type TextStyle = typeof styles[number]
export type TextColor = typeof colors[number]
export type TextGradient = typeof gradients[number]
export type TextAlign = typeof aligns[number]

export type TextProps = {
  children?: React.ReactNode,
  className?: string
  message?: string
  tag?: string
  style: TextStyle,
  mobStyle?: TextStyle,
  color?: TextColor
  gradient?: TextGradient
  align?: TextAlign
  html?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  dataTestId?: string
}

const Text = React.forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  let {
    children, className, message, tag = 'div',
    style, mobStyle, color = 'inherit', align, html,
    onClick, dataTestId, gradient,
  } = props

  if (mobStyle) {
    return <TextProxy {...props} />
  }

  if (onClick && tag !== 'button') {
    console.error('You can\'t use "onClick" without passing tag === "button". Create components ADA friendly!')
  }

  const textClassName = cx(s.text, className, {
    [s[style]]: style,
    [`color-${color}`]: color,
    [`text-${align}`]: align,
    [cx(s.gradient, s[`gradient-${gradient}`])]: gradient,
  })

  if (html) {
    return React.createElement(tag, {
      className: textClassName,
      dangerouslySetInnerHTML: { __html: message },
      onClick,
    })
  }

  const content = children || message

  return (
    React.createElement(tag, {
      className: textClassName,
      onClick,
      'data-testid': dataTestId,
      ref,
    }, content)
  )
})

export default Text
