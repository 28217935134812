import { Text } from 'components/dataDisplay'
import { ActiveLink } from 'components/navigation'
import { Icon } from 'components/ui'
import React, { FC, useState, useEffect } from 'react'
import s from './MobileMenu.module.scss'
import cx from 'classnames'
import { useFreezeBodyScroll } from 'hooks'
import { navItems } from '../navItems'


type MobileMenuProps = {
  handleClose: () => void
}

const MobileMenu: FC<MobileMenuProps> = ({ handleClose }) => {
  const [ isOpened, setIsOpened ] = useState(false)
  useFreezeBodyScroll()

  useEffect(() => {
    setTimeout(() => setIsOpened(true), 0)
  }, [])

  const onClose = () => {
    setIsOpened(false)
    setTimeout(() => handleClose(), 300)
  }

  return (
    <div className={cx(s.container, isOpened && s.open)}>
      <div className={s.closeContainer}>
        <div className={s.closeBtn} onClick={onClose}>
          <Icon name="interface/close" size={20} color="gray-60" />
        </div>
      </div>
      {
        navItems.map(item => (
          <ActiveLink
            href={item.href}
            key={item.href}
            activeClassName={s.active}
            exact
          >
            <div className={s.itemContainer} onClick={onClose}>
              <Icon name="interface/arrow-right-bold" color="gray-100" size={16} className={s.arrow} />
              <Text message={item.title} style="u14" color="gray-100" className="mb-2" />
              <Text message={item.description} style="c14" color="gray-60" />
            </div>
          </ActiveLink>
        ))
      }
    </div>
  )
}

export default MobileMenu
