import React, { FC, useEffect } from 'react'
import s from './NotificationCard.module.scss'
import { Text } from 'components/dataDisplay'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useProfile } from 'context'
import { event } from 'helpers'


type NotificationCardProps = {
  notification: ServerNotification
}

const NotificationCard: FC<NotificationCardProps> = ({ notification }) => {
  const { id, listing_id, title, text, created_at, seen } = notification

  const { markNotificationAsSeen } = useProfile()

  useEffect(() => {
    if (!seen) {
      const timeoutId = setTimeout(() => markNotificationAsSeen(id), 3000)

      event('notification_profile', {
        action: 'close',
      })

      return () => clearTimeout(timeoutId)
    }
  }, [])

  return (
    <div className={s.container}>
      { !seen && <div className={s.indicator} /> }
      <Text className="uppercase" style="c14" message={title} color="gray-100" />
      <Text
        className="mt-4 mb-8"
        style="c14"
        color="gray-60"
      >
        {text}
        {' '}
        <Link href={`/listing/${listing_id}`}>
          <a role="link">visit raffle page</a>
        </Link>
      </Text>
      <Text style="c12" message={`${dayjs(created_at * 1000).fromNow()}`} color="gray-40" />
    </div>
  )
}

export default NotificationCard
