import React, { FC, useMemo } from 'react'
import s from './Pagination.module.scss'
import cx from 'classnames'
import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'
import { useDevice } from 'device'


type PaginationProps = {
  className?: string
  pages: number
  page: number
  setPage: (n: number) => void
  scroll?: () => void
}

const Pagination: FC<PaginationProps> = ({ className, pages, page, setPage, scroll }) => {
  const { isMobile } = useDevice()
  const SHOW_MAX_PAGES = isMobile ? 2 : 4

  const totalPages = Array.from({ length: Math.ceil(pages) }, (x, i) => i+1)

  const isMaxPages = totalPages.length > SHOW_MAX_PAGES

  const showPages = useMemo(() => {
    if (isMaxPages) {
      const firstPage = (page - SHOW_MAX_PAGES / 2) < 0 ? 0 : page - SHOW_MAX_PAGES / 2
      const lastPage = (page + SHOW_MAX_PAGES / 2) >= totalPages.length ? totalPages.length : page + SHOW_MAX_PAGES / 2

      return totalPages.slice(firstPage, lastPage)
    }

    return totalPages
  }, [ isMaxPages, totalPages ])

  if (totalPages.length < 2) {
    return null
  }

  const updatePage = (n: number) => {
    setPage(n)
    scroll && scroll()
  }

  const Dots: FC = () => <Text className="mx-10" style="c16" color="gray-20">...</Text>

  return (
    <div className={cx('flex items-center gap-4', s.container, className)}>
      <div className={cx('flex gap-4', s.button, s.arrow)} onClick={() => updatePage(page > 1 ? page - 1 : page)}>
        <Icon name="interface/arrow-left-bold" size={20} />
        <Text style="glitch24" color="inherit" message="Prev" className={s.mobileHide} />
      </div>
      {
        (showPages[0] !== 1) && (
          <>
            <div className={cx(s.button, page === 1 && s.active)} onClick={() => updatePage(1)}>
              <Text style="c16" color="inherit">
                {1}
              </Text>
            </div>
            { showPages[0] > 2 && <Dots /> }
          </>
        )
      }
      {
        showPages.map(key => {
          return (
            <div key={key} className={cx(s.button, page === key && s.active)} onClick={() => updatePage(key)}>
              <Text style="glitch24" color="inherit">
                {key}
              </Text>
            </div>
          )
        })
      }
      {
        showPages[showPages.length - 1] !== totalPages[totalPages.length - 1] && (
          <>
            { showPages[showPages.length - 1] < totalPages[totalPages.length - 2] && <Dots /> }
            <div className={cx(s.button, page === totalPages[totalPages.length - 1] && s.active)} onClick={() => updatePage(totalPages[totalPages.length - 1])}>
              <Text style="glitch18" color="inherit">
                {totalPages[totalPages.length - 1]}
              </Text>
            </div>
          </>
        )
      }
      <div className={cx('flex gap-4', s.button, s.arrow)} onClick={() => updatePage(totalPages[totalPages.length - 1] <= page ? page : page + 1)}>
        <Text style="glitch24" color="inherit" message="Next" className={s.mobileHide} />
        <Icon name="interface/arrow-right-bold" size={20} />
      </div>
    </div>
  )
}

export default Pagination
