import { useQuery } from 'hooks'
import { getAlchemyInstance, safePromise } from 'helpers'
import { useChainId } from 'chain-id'
import { parseNftInfo } from 'helpers/parseNftInfo'
import type { OwnedNft } from 'alchemy-sdk'


const useOwnedNFTs = (address: string) => {
  const { selectedChainId } = useChainId()

  const fetcher = async () => {
    let list: OwnedNft[] = []

    const alchemy = getAlchemyInstance(selectedChainId)

    while (true) {
      let pageKey: string

      const response = await alchemy.nft.getNftsForOwner(address, { pageKey })

      list = list.concat(response.ownedNfts)

      if (response.pageKey) {
        pageKey = response.pageKey
      }
      else {
        break
      }
    }

    return await safePromise(list.map(async nft => parseNftInfo(nft, address)))
  }

  const { isFetching, data } = useQuery({
    endpoint: [ '/get-owned-nft', address ],
    fetcher,
    skip: !address,
  })

  return {
    isFetching, data: data || [],
  }
}

export default useOwnedNFTs
