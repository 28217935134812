import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { constants } from 'helpers'


const meta = {
  title: 'Babylon7 – Where Art Fortune Is Made',
  description: 'Babylon7 is a platform dedicated to high-end NFT art raffles that gives everybody a chance to own rare digital pieces at a fraction of the price.',
  image: '/images/graphics/og.jpg',
}

// https://css-tricks.com/essential-meta-tags-social-media/
const Meta: React.FC = () => {
  const { pathname } = useRouter()

  const { title, description, image } = meta

  const url = `${constants.origin}${pathname}`
  const imageUrl = `${constants.origin}${image}`

  return (
    <Head>
      <title>
        {title}
      </title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:description" content={description} key="ogDescription" />
      <meta property="og:image" content={imageUrl} key="ogImage" />
      <meta property="og:type" content="website" key="ogType" />
      <meta property="og:url" content={url} key="ogUrl" />
      <meta property="og:site" content="Babylon" key="ogSite" />
      <meta property="og:site_name" content="Babylon" key="ogSite_name" />
      <meta name="twitter:title" content={title} key="twitterTitle" />
      <meta name="twitter:description" content={description} key="twitterDescription" />
      <meta name="twitter:image" content={imageUrl} key="twitterImage" />
      <meta name="twitter:card" content="summary_large_image" key="twitterCard" />
    </Head>
  )
}

export default Meta
