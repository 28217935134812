import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import type { IconName } from './icons'
import InlineSvg from '../InlineSvg/InlineSvg'
import s from './Icon.module.scss'


export const sizes = [ 12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 116, 164, 180 ] as const

export const colors = [
  'inherit',
  'yellow',
  'accent-red-60',
  'accent-red-50',
  'accent-red-10',
  'accent-green-60',
  'accent-green-50',
  'accent-green-10',
  'gray-5',
  'gray-10',
  'gray-20',
  'gray-40',
  'gray-60',
  'gray-80',
  'gray-100',
  'bg-1',
  'bg-2',
  'bg-3',
] as const

export type IconSize = typeof sizes[number]

export type IconColor = typeof colors[number]

type IconProps = {
  className?: string
  name: IconName
  size?: IconSize
  color?: IconColor
  'aria-describedby'?: string
}

const IconBase: React.FunctionComponent<IconProps> = (props) => {
  const { className, name, size, color, 'aria-describedby': ariaDescribedby } = props

  const rootClassName = cx(s.icon, className, s[name], s[`size-${size}`], {
    [`color-${color}`]: color,
  })

  return (
    <InlineSvg
      className={rootClassName}
      src={`/images/icons/${name}.svg`}
      aria-describedby={ariaDescribedby}
    />
  )
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  return <IconBase {...props} />
}


export default Icon
