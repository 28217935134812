import React, { FC } from 'react'
import { Button, ButtonProps } from 'components/inputs'
import { event } from 'helpers'
import { openConnectWalletModal } from 'compositions/modals/ConnectWalletModal/ConnectWalletModal'
import { useRouter } from 'next/router'
import { Icon } from 'components/ui'
import s from './ConnectButton.module.scss'


const ConnectButton: FC<Partial<ButtonProps> & { mini?: boolean }> = (props) => {
  const router = useRouter()
  const { mini, style } = props

  const handleClick = () => {
    const version = router.pathname === '/' ? 'home' : 'listing'
    const listingID = router?.query?.listingId as string

    event('connect_wallet', { version, listingID })
    openConnectWalletModal()
  }

  if (mini) {
    return (
      <div className={s.mini} onClick={handleClick}>
        <Icon name="interface/connect" size={20} />
      </div>
    )
  }

  return (
    <Button
      style="primary"
      title="Connect wallet"
      leftIcon="interface/connect"
      onClick={handleClick}
      tag={'div'}
      {...props}
    />
  )
}

export default ConnectButton
