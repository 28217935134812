import React from 'react'
import { modalVisibility, openModal, closeModal } from '@locmod/modal'
import type { ModalVisibilityProps } from '@locmod/modal'
import { PlainModal } from 'components/feedback'
import useCreateAccount from './utils/useCreateAccount'
import Submitting from './components/Submitting/Submitting'
import FormComponent from './components/Form/Form'
import Created from './components/Created/Created'


const CreateAccountModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props
  const { form, createProfile, isSubmitting, isCreated } = useCreateAccount()

  return (
    <PlainModal
      closeModal={closeModal}
      overlayClosable={false}
    >
      <div className="p-24 text-center">
        {
          isCreated && (
            <Created website={form.fields.email.state.value.split('@')[1]} />
          )
        }
        {
          !isCreated && isSubmitting && (
            <Submitting />
          )
        }
        {
          !isCreated && !isSubmitting && (
            <FormComponent
              closeModal={closeModal}
              create={createProfile}
              form={form}
            />
          )
        }
      </div>
    </PlainModal>
  )
}

export const openCreateAccountModal = () => openModal('CreateAccountModal')
export const closeCreateAccountModal = () => closeModal('CreateAccountModal')

export default modalVisibility('CreateAccountModal', CreateAccountModal)
