const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isEmpty = (value: any): boolean => (
  typeof value === 'undefined'
  || value === null
  || value === ''
  || /^\s+$/.test(value)
)

export const required = (value: any) => {
  if (isEmpty(value)) {
    return 'required'
  }
}

export const validateEmail = (value: string) => {
  return emailCheck.test(value) ? undefined : 'please enter valid email'
}

export const validateUrl = (value: string) => {
  return /\w+\.\w+/.test(value) ? undefined : 'should contain a valid url'
}

export const upTo10 = (value: string) => {
  return parseFloat(value) >= 0 && parseFloat(value) <= 10 ? undefined : 'should be between 0 and 10'
}

export const upTo10Symbols = (value: string) => {
  if (!value) {
    return undefined
  }

  return value.length <= 20 ? undefined : 'Cannot be more than 20 symbols'
}

export const EmptyOrUrl = (value: string) => {
  if (!value) {
    return
  }

  return validateUrl(value)
}

export const upToAmount = (value: string, amount: number) => {
  if (!value) {
    return undefined
  }

  return parseFloat(value) >= 0 && parseFloat(value) <= amount ? undefined : `should be between 0 and ${amount}`
}
