import { useEffect, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import type { Web3Provider } from '@ethersproject/providers'
import type { AbstractConnector } from '@web3-react/abstract-connector'
import localStorage from '@locmod/local-storage'
import { useChainId } from 'chain-id'

import constants from './constants'
import connectors from './connectors'
import type { ConnectorNames } from './connectors'


type Output = {
  account: string
  chainId: number
  supportedChainIds: number[]
  library: Web3Provider
  connector: AbstractConnector
  isActive: boolean
  isMainnet: boolean
  isRightNetwork: boolean
  isSupportedNetwork: boolean
  error: string | null
  disconnect: () => void
  connect: (connectorName: ConnectorNames, callback?: () => void) => Promise<any>
}

const removeConnectorName = () => localStorage.removeItem(constants.connectorName)

const useConnect = (): Output => {
  const web3React = useWeb3React()
  const { selectedChainId, setChainId } = useChainId()

  const { account, library, chainId, connector, active, activate, deactivate, error } = web3React

  const isRightNetwork = chainId === selectedChainId
  const isSupportedNetwork = constants.supportedChainIds.includes(chainId)

  useEffect(() => {
    // reset connector if user rejected the first time
    // https://github.com/NoahZinsmeister/web3-react/issues/217
    if (error?.name === 'UserRejectedRequestError') {
      // @ts-ignore
      connector.walletConnectProvider = undefined
    }
  }, [ error ])

  useEffect(() => {
    // change selected chain id after connect
    if (isSupportedNetwork) {
      setChainId(chainId)
    }
  }, [ chainId ])

  const connect = useCallback<Output['connect']>(async (connectorName) => {

    return activate(connectors[connectorName], (err) => console.log(err), true)
      .then(() => {
        setTimeout(() => activate(connectors[connectorName])) // call second time to fix query param not connecting issue
        localStorage.setItem(constants.connectorName, connectorName)
        connectors[connectorName].on('Web3ReactDeactivate', removeConnectorName)
      })
  }, [ selectedChainId ])

  const disconnect = useCallback<Output['disconnect']>(async () => {
    const connectorName = localStorage.getItem<string>(constants.connectorName)

    connectors[connectorName].off('Web3ReactDeactivate', removeConnectorName)
    localStorage.removeItem(constants.connectorName)
    deactivate()
  }, [])

  return {
    account,
    library,
    chainId,
    supportedChainIds: constants.supportedChainIds,
    connector,
    error: error instanceof Error ? error.message : null,
    isActive: active,
    isMainnet: chainId === 1,
    isSupportedNetwork,
    connect,
    disconnect,
    isRightNetwork,
  }
}


export default useConnect
