import React, { useRef, useCallback, useEffect, useState } from 'react'
import type { Field } from 'formular'
import { useFieldState } from 'formular'
import { required } from 'helpers/validators'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import AutosizeTextarea from './AutosizeTextarea'
import type { AutosizeTextareaProps } from './AutosizeTextarea'

import s from './Textarea.module.scss'


export type TextareaProps = Omit<AutosizeTextareaProps, 'placeholder'> & {
  field: Field<string>
  placeholder?: string
  focusOnInit?: boolean
  label?: string
  disabled?: boolean
  captionLeft?: string
  subNode?: React.ReactElement
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  onChange?: (value: any) => void
  dataTestId?: string
  loading?: boolean
}

const Textarea: React.FunctionComponent<TextareaProps> = (props) => {
  const {
    className, field, placeholder, rows = 4,
    focusOnInit, disabled, label,
    captionLeft, subNode,
    onFocus, onBlur, onChange, onResize, dataTestId,
  } = props

  const ref = useRef<HTMLTextAreaElement>()

  const { value, error } = useFieldState<string>(field)
  const [ isFocused, setIsFocused ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const isSmallLabel = !disabled && (isActive || value || isFocused)

  useEffect(() => {
    if (focusOnInit && ref.current) {
      ref.current.focus()
    }
  }, [])

  const handleBlur = useCallback(async (event) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await field.validate()
    setIsActive(false)

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ field, onBlur ])

  const handleFocus = useCallback((event) => {
    setIsActive(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ] )

  const handleChange = useCallback((event) => {
    const value = event.target.value

    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  const isFilled = value !== ''
  const isErrored = Boolean(error)

  const textareaClassName = cx(s.textarea, {
    [s.filled]: isFilled,
    [s.errored]: isErrored,
    [s.disabled]: disabled,
  })

  const isRequired = field.validators.includes(required)

  return (
    <div className={className} onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
      <div className={cx(s.borderContainer, Boolean(label) && s.withLabel)}>
        {
          Boolean(label) && (
            <Text className={cx(s.label, isSmallLabel && s.label_small)} style="c16" message={label} color="gray-60" />
          )
        }
        <AutosizeTextarea
          ref={ref}
          className={textareaClassName}
          value={value}
          placeholder={label && !isSmallLabel ? '' : placeholder}
          rows={rows}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onResize={onResize}
          aria-required={isRequired}
          aria-multiline
          data-testid={dataTestId}
        />
      </div>
      {
        (captionLeft || subNode || isErrored) && (
          <div className="mt-4 pl-4">
            {
              captionLeft && !isErrored && (
                <Text message={captionLeft} style="c16" />
              )
            }
            {
              subNode && !isErrored && (
                subNode
              )
            }
            {
              isErrored && (
                <Text message={error} style="c16" color="accent-red-50" />
              )
            }
          </div>
        )
      }
    </div>
  )
}


export default React.memo(Textarea)
