import React, { useRef, useEffect, useState, useCallback } from 'react'
import type { Field } from 'formular'
import { useFieldState } from 'formular'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import s from './Input.module.scss'
import { Icon } from 'components/ui'
import { Tooltip } from 'components/feedback'


export type InputProps = {
  className?: string
  field: Field<string | number>
  pattern?: string
  placeholder?: string
  focusOnInit?: boolean
  disabled?: boolean
  label?: string
  labelTooltip?: {
    title?: string
    text: string
  }
  validateOnBlur?: boolean
  captionLeft?: string
  captionRight?: string
  subNode?: React.ReactElement
  onBlur?: (value: string) => void
  onFocus?: (value: string) => void
  onChange?: (value: string) => void
  dataTestId?: string
  symbol?: string
  loading?: boolean
  successMessage?: string
}

const Input: React.FunctionComponent<InputProps> = (props) => {
  let {
    className, field, pattern, placeholder, label,
    focusOnInit, disabled, validateOnBlur = true,
    captionLeft, captionRight, subNode, symbol, loading,
    onFocus, onBlur, onChange, dataTestId, labelTooltip,
    successMessage,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const { value, error } = useFieldState<string | number>(field)
  const [ isFocused, setIsFocused ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const isSmallLabel = !disabled && (isActive || value || isFocused)

  useEffect(() => {
    if (focusOnInit) {
      inputRef.current.focus()
    }
  }, [])

  const handleFocus = useCallback((event) => {
    let value = event.target.value
    setIsActive(true)

    if (typeof onFocus === 'function') {
      onFocus(value)
    }
  }, [ onFocus ])

  const handleBlur = useCallback(async (event) => {
    let value = event.target.value
    setIsActive(false)

    if (validateOnBlur) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await field.validate()
    }

    if (typeof onBlur === 'function') {
      onBlur(value)
    }
  }, [ field, onBlur ])

  const handleChange = useCallback((event) => {
    let value = event.target.value

    if (pattern && !new RegExp(pattern).test(value)) {
      return
    }

    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ onChange, pattern ])

  const isFilled = value !== ''
  const isErrored = Boolean(error)
  const isSuccess = Boolean(successMessage)

  const rootClassName = cx(s.root, className)

  const inputClassName = cx(s.input, {
    [s.disabled]: disabled,
  })

  const borderClassName = cx(s.borderContainer, {
    [s.filled]: isFilled,
    [s.success]: isSuccess,
    [s.errored]: isErrored,
    [s.disabled]: disabled,
    [s.withLabel]: Boolean(label),
  })

  const labelColor = isErrored ? 'accent-red-50' : isSuccess ? 'accent-green-50' : disabled ? 'gray-10' : 'gray-60'

  return (
    <div className={rootClassName} onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
      <div className={borderClassName}>
        {
          Boolean(label) && (
            <Text className={cx(s.label, isSmallLabel && s.label_small)} style="c16" message={label} color={labelColor} />
          )
        }
        <input
          data-testid={dataTestId}
          className={inputClassName}
          value={value}
          ref={inputRef}
          placeholder={label && !isSmallLabel || loading ? '' : placeholder as string}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {
        loading && (
          <div className={s.loading}>
            <Icon name="interface/spinner" />
          </div>
        )
      }
      {
        symbol && (
          <Text className={cx(s.symbol, labelTooltip && s.symbol_withLabel)} style="c16" color="gray-40">
            {symbol}
          </Text>
        )
      }
      {
        labelTooltip && (
          <Tooltip {...labelTooltip}>
            <div className={s.symbol}>
              <Icon className="mb-8" name="interface/question" size={20} />
            </div>
          </Tooltip>
        )
      }
      <div className={s.subContainer}>
        {
          captionLeft && !isErrored && !isSuccess && (
            <Text className="uppercase" style="c12" message={`{ ${captionLeft} }`} color="gray-40" />
          )
        }
        {
          captionRight && !isErrored && !isSuccess && (
            <Text className="uppercase" style="c12" message={`{ ${captionRight} }`} />
          )
        }
        {
          subNode && !isErrored && !isSuccess && (
            subNode
          )
        }
        {
          isSuccess && !isErrored && (
            <Text className="uppercase" message={`[ ${successMessage} ]`} style="c12" color="accent-green-50" />
          )
        }
        {
          isErrored && (
            <Text className="uppercase" message={`[ ${error} ]`} style="c12" color="accent-red-50" />
          )
        }
      </div>
    </div>
  )
}


export default React.memo(Input)
