import React, { FC, useMemo } from 'react'
import s from './NotificationsButton.module.scss'
import { Icon } from 'components/ui'
import cx from 'classnames'
import { useProfile } from 'context'
import { Text } from 'components/dataDisplay'
import { Dropdown } from 'components/inputs'
import NotificationsDropdown from '../NotificationsDropdown/NotificationsDropdown'


type NotificationsButtonProps = {
  className?: string
}

const Count: FC<{ count: number, className?: string }> = ({ count, className }) => {
  return (
    <div className={cx(s.container, s.count, className)}>
      <Text style="glitch18" message={String(count)} />
    </div>
  )
}

const Bell: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cx(s.container, s.bell, className)}>
      <Icon name="interface/bell" size={20} />
    </div>
  )
}

const NotificationsButton: FC<NotificationsButtonProps> = ({ className }) => {
  const { notifications } = useProfile()

  const unseenCount = useMemo<number>(
    () => notifications.filter(n => n.seen === false).length,
    [ notifications ]
  )

  return (
    <Dropdown
      name="notifications"
      content={<NotificationsDropdown unseenCount={unseenCount} />}
      placement="bottomRight"
      mobileClosable
    >
      {
        ({ isOpen }) =>
          unseenCount ?
            <Count className={cx(className, isOpen && s.active)} count={unseenCount} />
            :
            <Bell className={cx(className, isOpen && s.active)} />
      }
    </Dropdown>
  )
}

export default NotificationsButton
