import React, { useEffect, useRef, useState } from 'react'
import Text, { TextProps } from '../Text/Text'
import { useOnScreen } from 'hooks'


interface QueueItem {
  from: string;
  to: string;
  start: number;
  end: number;
  char?: string;
  time?: number
}

type ScrambledTextProps = TextProps & {
  time?: number
}

const chars = '@$%∑W____##∃∞∟∩∭⟏×'

const ScrambledText: React.FC<ScrambledTextProps> = ({ time = 120, ...props }) => {
  const elRef = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(elRef)
  const [ played, setPlayed ] = useState(false)
  let resolve: () => void
  let queue: QueueItem[] = []
  let frameRequest: number
  let frame: number

  useEffect(() => {
    const el = elRef.current

    if (!el || played) {
      return
    }

    function setText(newText: string): Promise<void> {
      const oldText = el.innerText
      const length = Math.max(oldText.length, newText.length)
      const promise = new Promise<void>((res) => (resolve = res))
      queue = []
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || ''
        const to = newText[i] || ''
        const start = Math.floor(Math.random() * time)
        const end = start + Math.floor(Math.random() * time)
        queue.push({ from, to, start, end })
      }
      cancelAnimationFrame(frameRequest)
      frame = 0
      update()
      setPlayed(true)

      return promise
    }

    function update(): void {
      let output = ''
      let complete = 0
      for (let i = 0, n = queue.length; i < n; i++) {
        let { from, to, start, end, char } = queue[i]

        if (frame >= end) {
          complete++
          output += to
        }
        else if (frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = randomChar()

            if (from === ' ' || to === ' ') {
              char = from
            }

            queue[i].char = char
          }
          output += char
        }
        else {
          output += from
        }
      }
      el.innerHTML = output

      if (complete === queue.length) {
        resolve()
      }
      else {
        frameRequest = requestAnimationFrame(update)
        frame++
      }
    }

    function randomChar(): string {
      return chars[Math.floor(Math.random() * chars.length)]
    }

    isVisible && setText(props?.message)
  }, [ props?.message, isVisible ])

  return <Text {...props} ref={elRef} />
}

export default ScrambledText
