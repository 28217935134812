import React, { FC, useState, useCallback, memo } from 'react'
import s from './MenuButton.module.scss'
import { Icon } from 'components/ui'
import MobileMenu from '../MobileMenu/MobileMenu'


type NotificationsButtonProps = {
  className?: string
}

const MenuButton: FC<NotificationsButtonProps> = () => {
  const [ isMenuOpen, setMenuOpen ] = useState(false)

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(prev => !prev)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  return (
    <>
      <div className={s.burger} onClick={handleToggleMenu}>
        <Icon name="interface/menu" size={20} color="gray-60" />
      </div>
      {isMenuOpen && <MobileMenu handleClose={handleCloseMenu} />}
    </>
  )
}

export default memo(MenuButton)
