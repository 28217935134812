import { FC, memo, useRef, useState } from 'react'
import s from './Accordion.module.scss'
import { Button } from 'components/inputs'
import { ScrambledText, Text } from 'components/dataDisplay'
import cx from 'classnames'
import { Icon } from 'components/ui'
import { useDevice } from 'device'


type FaqItem = {
  question: string,
  answer: string,
}

const AccordionItem: FC<{faq: FaqItem, onToggle: () => void, isActive: boolean}> = ({ faq, onToggle, isActive }) => {
  const { isMobile } = useDevice()
  const { question, answer } = faq
  const contentEl = useRef<HTMLDivElement>(null)

  return (
    <li className={cx(s.item, { [s.open]: isActive }, 'py-20 px-24', isMobile ? 'mb-2' : 'mb-12')}>
      <div className={cx(s.question, 'flex justify-between items-center gap-12')} onClick={onToggle}>
        <ScrambledText style="h6" tag="h6" message={question} />
        <Button size={28} style={isActive ? 'primary' : 'white'}>
          {isActive ? <Icon name={'interface/minus'} size={20} /> : <Icon name={'interface/plus'} size={20} />}
        </Button>
      </div>
      <div
        className={cx(s.content, { [s.open]: isActive })}
        ref={contentEl}
        style={
          isActive
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        <ScrambledText
          key={`${question}_${isActive}`}
          style="p1"
          tag="p"
          message={answer}
          color="gray-60"
          className={isMobile ? 'mt-16' : 'mt-12'}
          time={30}
        />
      </div>
    </li>
  )
}

type AccordionProps = {
  data: FaqItem[]
  className?: string
}

const Accordion: FC<AccordionProps> = ({ data, className }) => {
  const [ open, setOpen ] = useState(null)

  const handleToggle = (index) => {
    if (open === index) {
      return setOpen(null)
    }
    setOpen(index)
  }

  return (
    <ul className={className}>
      {
        data.map((faq, index) => (
          <AccordionItem key={faq.question} faq={faq} onToggle={() => handleToggle(index)} isActive={open === index} />
        ))
      }
    </ul>
  )
}

export default memo(Accordion)
