import constants from './constants'
import getTimestamp from './getTimestamp'
import localStorage from './localStorage'


type JWT = {
  exp: number
  iat: number
  isAdmin: boolean
  user_address: string
}

const emptyValue = {
  isExpired: true,
  isAdmin: false,
  isAccount: () => false,
  token: null,
}

const jwt = () => {
  if (typeof window === 'undefined') {
    return emptyValue
  }

  const token = localStorage.getItem<string>(constants.localStorage.token)

  try {
    const parsedResult: JWT = JSON.parse(atob(token.split('.')[1]))

    return {
      isExpired: parsedResult.exp < getTimestamp(),
      isAdmin: parsedResult.isAdmin,
      isAccount: (account: string) => account?.toLowerCase() === parsedResult.user_address.toLowerCase(),
      token: token,
    }
  }
  catch {
    return emptyValue
  }
}

export default jwt
