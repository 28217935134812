import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import cx from 'classnames'
import s from './TextRepetition.module.scss'
import { getHeight } from 'helpers/getHeight'
import Text, { TextProps } from 'components/dataDisplay/Text/Text'


interface TextRepetitionProps extends TextProps {
  totalWords?: number
  tyIncrement?: number
  delayIncrement?: number
}

const TextRepetition: React.FC<TextRepetitionProps> = ({ totalWords = 7, tyIncrement = 7, delayIncrement = 0.08, ...props }) => {
  const elRef = useRef<HTMLDivElement>(null)
  const scrollTimeline = useRef<gsap.core.Timeline>(null)
  const isLoaded = useRef<boolean>(null)

  const layout = () => {
    const halfWordsCount = Math.floor(totalWords/2)
    let innerHTML = ''

    for (let i = 0; i < totalWords; ++i) {

      let ty
      let delay

      if ( i === totalWords-1 ) {
        ty = 0
        delay = 0
      }
      else if ( i < halfWordsCount ) {
        ty = halfWordsCount*tyIncrement-tyIncrement*i
        delay = delayIncrement*(halfWordsCount-i)-delayIncrement

      }
      else {
        ty = -1*(halfWordsCount*tyIncrement-(i-halfWordsCount)*tyIncrement)
        delay = delayIncrement*(halfWordsCount- (i-halfWordsCount) )-delayIncrement
      }

      innerHTML += `<span data-delay="${delay}" data-ty="${ty}">${elRef.current?.innerHTML}</span>`
    }

    elRef.current.innerHTML = innerHTML

    return [ ...elRef.current.querySelectorAll('span') ].slice(0, -1)
  }

  const setBoundaries = () => {
    const paddingBottomMarginTop = getHeight(elRef.current) * Math.floor(totalWords/2) * tyIncrement/100
    gsap.set(elRef.current, {
      marginTop: paddingBottomMarginTop,
      paddingBottom: paddingBottomMarginTop,
    })
  }

  const createScrollTimeline = (words: HTMLSpanElement[]) => {
    scrollTimeline.current = gsap.timeline({ paused: true })
      .to(words, {
        duration: 1,
        ease: 'none',
        startAt: { opacity: 0 },
        opacity: 1,
        yPercent: (_, target) => target.dataset.ty,
        delay: (_, target) => target.dataset.delay,
      }, 0)
      .to(words, {
        duration: 1,
        ease: 'none',
        opacity: 0,
        yPercent: 0,
        delay: (_, target) => target.dataset.delay,
      })
  }

  const progressTween = () => {
    const scrollPosition = (window?.scrollY + window?.innerHeight)
    const elPosition = (scrollPosition - elRef.current?.offsetTop)
    const durationDistance = (window.innerHeight + elRef.current?.offsetHeight)
    const currentProgress = (elPosition / durationDistance)
    scrollTimeline.current.progress(currentProgress)
  }

  useEffect(() => {
    const el = elRef.current

    if (!el) {
      return
    }

    const words = layout()
    setBoundaries()
    createScrollTimeline(words)

    const observer = new IntersectionObserver(entry => {
      if (entry[0].intersectionRatio > 0) {
        if ( !isLoaded.current ) {
          isLoaded.current = true
        }
        gsap.ticker.add(progressTween)
      }
      else {
        if ( isLoaded.current ) {
          gsap.ticker.remove(progressTween)
        }
        else {
          isLoaded.current = true
          gsap.ticker.add(progressTween, true)
        }
      }
    }, {
      root: null,
      rootMargin: '0px 0px',
      threshold: 0,
    })
    observer.observe(el)

    window.addEventListener('resize', setBoundaries)

    return () => {
      window.removeEventListener('resize', setBoundaries)
      observer.disconnect()
    }
  }, [])

  return <Text {...props} ref={elRef} className={cx(s.rep, props?.className)} />
}

export default TextRepetition
