import React, { FC } from 'react'
import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'


const Submitting: FC = () => {
  return (
    <>
      <Text style="h6" message="Waiting for signature" />
      <Text
        className="mt-8"
        style="c14"
        html
        message="Confirm signature in your wallet <br /> extension to create a profile"
        color="gray-60"
      />
      <Icon className="mt-24" name="interface/spinner" size={48} />
    </>
  )
}

export default Submitting
