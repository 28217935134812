import React, { memo } from 'react'
import Link from 'next/link'

import s from './Logo.module.scss'


const Logo: React.FC = () => {
  return (
    <Link href="/" passHref>
      <a className={s.logo} title="Babylon">
        <img src="/images/logos/b7.svg" alt="Babylon 7 logo" className="w-full" />
      </a>
    </Link>
  )
}

export default memo(Logo)
