import React from 'react'

import { AccountButton, Logo, NotificationsButton, MenuButton } from '../../components'
import ConnectButton from 'compositions/PageLayout/components/ConnectButton/ConnectButton'
import { useConnect } from 'web3'
import { ConnectingContext } from 'local_modules/web3/Connector'
import { Bone } from 'components/feedback'
import s from './Mobile.module.scss'
import { ScrambledText } from 'components/dataDisplay'


const Mobile: React.FC<{showLogo: boolean}> = ({ showLogo }) => {
  const { account } = useConnect()
  const isConnecting = React.useContext(ConnectingContext)

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-4">
        <MenuButton />
        <NotificationsButton />
      </div>
      <div className={s.logo}>
        {showLogo ? <Logo /> : <ScrambledText style="glitch18" message="Rebuilding gallery ..." />}
      </div>
      {
        isConnecting ? <Bone w={48} h={48} rounded /> : account ? <AccountButton /> : (
          <div>
            {
              showLogo && (
                <div className={s.connect}>
                  <ConnectButton size={48} mini />
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default Mobile
