import React, { forwardRef } from 'react'
import cx from 'classnames'

import { Icon, IconSize } from 'components/ui'
import type { IconName } from 'components/ui'
import { ButtonBase } from 'components/inputs'
import type { ButtonBaseProps } from 'components/inputs'

import s from './Button.module.scss'
import { Text } from 'components/dataDisplay'


export const sizes = [ 28, 32, 40, 48, 56 ] as const
export const styles = [ 'primary', 'white' ] as const

type ButtonSize = typeof sizes[number]
type ButtonStyle = typeof styles[number]

const iconSizes: {[key in ButtonSize]: IconSize} = {
  28: 12,
  32: 12,
  40: 14,
  48: 16,
  56: 18,
}

export type ButtonProps = ButtonBaseProps & OnlyOne<{
  children: React.ReactNode | undefined
  title: string
  leftIcon?: IconName
  rightIcon?: IconName
  size?: ButtonSize
  style?: ButtonStyle
  fullWidth?: boolean
  fullWidthOnMobile?: boolean
  dataTestId?: string
  tag?: string
  target?: string
}, 'children' | 'title'>

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>((props, ref) => {
  let {
    children, className, title, size = 56, style = 'primary',
    loading, disabled, fullWidth, fullWidthOnMobile,
    leftIcon, rightIcon, tag = 'button',
    ...rest
  } = props

  if (loading) {
    leftIcon = 'interface/spinner'
  }

  const rootClassName = cx(s.button, className, 'button', {
    [s[style]]: s[style],
    [s[`size-${size}`]]: size,
    'w-full': fullWidth,
    [s.fullWidthOnMobile]: fullWidthOnMobile,
    [s.disabled]: loading || disabled,
  })

  return (
    <ButtonBase
      ref={ref}
      className={rootClassName}
      loading={loading}
      disabled={disabled}
      tag={tag}
      {...rest}
    >
      <div className={s.content}>
        {
          leftIcon && (
            <Icon
              className="mr-6"
              name={leftIcon}
              size={iconSizes[size]}
            />
          )
        }
        {title ? <Text style={size === 56 ? 'button-l' : 'button-s'} message={title} color="inherit" /> : children}
        {
          rightIcon && (
            <Icon
              className="ml-6"
              name={rightIcon}
              size={iconSizes[size]}
            />
          )
        }
      </div>
    </ButtonBase>
  )
})


export default Button
