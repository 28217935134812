import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import type { DropdownProps } from '../../Dropdown'
import s from './ExceptMobile.module.scss'


const ExceptMobile: React.FC<DropdownProps> = (props) => {
  const { children, className, dropListClassName, content, placement = 'bottomLeft', disabled } = props

  const [ isOpen, setIsOpen ] = useState(false)

  const rootClassName = cx('relative flex', className)

  const menuClassName = cx(s.menu, dropListClassName, {
    [s[placement]]: placement,
  })

  const btnClassName = cx(s.btn, {
    [s.disable]: !content,
  })

  const handleButtonClick = (event) => {
    event.stopPropagation()
    setIsOpen(prev => !prev)
  }

  const handleContainerClick = (event) => {
    event.stopPropagation()

    let element = event.target
    // in order to close dropdown with onClick,
    // element's attribute must be button or link
    while (element && element?.getAttribute) {
      const role = element.getAttribute('role')

      if (role === 'button' || role === 'link') {
        return setIsOpen(false)
      }

      element = element.parentNode
    }
  }

  useEffect(() => {
    const handleClickOutside = () => setIsOpen(false)
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [])


  return (
    <div className={rootClassName}>
      <div className={btnClassName} onClick={handleButtonClick}>
        {
          typeof children === 'function' ?
            children({ isOpen }) : (
              <>
                {children}
              </>
            )
        }
      </div>

      {
        isOpen && !disabled && (
          <div className={cx(s.content, menuClassName)} onClick={handleContainerClick}>
            {content}
          </div>
        )
      }
    </div>
  )
}

export default ExceptMobile
