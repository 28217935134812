import React, { FC } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import s from './User.module.scss'
import Avatar from '../Avatar/Avatar'
import Text from '../Text/Text'
import { shortenAddress } from 'helpers'
import { Bone } from 'components/feedback'
import { useFetchUser } from 'hooks'


type UserProps = {
  className?: string
  address: string
}

const User: FC<UserProps> = ({ className, address }) => {
  const { isFetching, data: user } = useFetchUser({ address })

  if (isFetching) {
    return (
      <div className={cx('flex items-center justify-between', className)}>
        <div className={s.userLink}>
          <div className="flex items-center">
            <Bone className="mr-8" w={24} h={24} rounded />
            <Bone className="mr-8 radius-8" w={100} h={20} />
          </div>
        </div>
      </div>
    )
  }

  const { display_name, avatar } = user || {}

  const displayedName = display_name || shortenAddress(address)

  return (
    <div className={cx('flex items-center justify-between', className)}>
      <Link href={`/user/${address}`}>
        <a className={s.userLink} title={displayedName}>
          <Avatar className="mr-8" size={24} address={address} src={avatar} />
          <Text style="c16" message={displayedName || shortenAddress(address)} />
        </a>
      </Link>
    </div>
  )
}

export default User
