export enum ListingState {
  Active,
  Resolving,
  Successful,
  Finalized,
  Canceled
}

export enum ItemType {
  ERC721,
  ERC1155
}

export enum EditionStorage {
  IPFS = 'ipfs',
  ARWeave = 'arweave'
}

export enum Sort {
  Featured = 'featured',
  PriceHighest = 'price_desc',
  PriceLowest = 'price_asc',
  Newest = 'date_desc',
  Oldest = 'date_asc'
}

export enum Gallery {
  open = 'open',
  curated = 'curated'
}
