import { useRef, useEffect } from 'react'


const timer = (endDate: number, cb: (value: string) => void) => {
  let interval: NodeJS.Timer

  const calculate = () => {
    const now = new Date().getTime()
    const distance = endDate - now

    if (distance < 0) {
      cb('0')
      clearInterval(interval)

      return
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    let hours: number | string = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutes: number | string = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    let seconds: number | string = Math.floor((distance % (1000 * 60)) / 1000)

    hours = hours < 10 ? `0${hours}` : hours
    minutes = minutes < 10 ? `0${minutes}` : minutes
    seconds = seconds < 10 ? `0${seconds}` : seconds

    const value = `${days ? `${days}d :` : ''} ${hours}h : ${minutes}m : ${seconds}s`

    cb(value)
  }

  calculate()

  interval = setInterval(calculate, 1000)

  return interval
}

type UseTimeLeftProps = {
  endDate: number
  finishText?: string
  onFinish?: () => void
}

const useTimeLeft = ({ endDate, finishText, onFinish }: UseTimeLeftProps) => {
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    const interval = timer(endDate, (value) => {
      if (! ref.current) {
        return
      }

      if (value === '0') {
        ref.current.innerText = finishText

        if (typeof onFinish === 'function') {
          onFinish()
        }
      }
      else {
        ref.current.innerText = value
      }
    })

    return () => {
      clearInterval(interval)
    }
  }, [])

  return ref
}


export default useTimeLeft
