import React, { useEffect, useState } from 'react'
import { useDevice } from 'device'

import { WidthContainer } from 'components/layout'
import WrongNetworkBanner from 'compositions/WrongNetworkBanner/WrongNetworkBanner'

import Desktop from './views/Desktop/Desktop'
import Mobile from './views/Mobile/Mobile'

import s from './Header.module.scss'
import { useRouter } from 'next/router'
import cx from 'classnames'
import ConnectWalletModal from 'compositions/modals/ConnectWalletModal/ConnectWalletModal'
import CreateAccountModal from 'compositions/modals/CreateAccountModal/CreateAccountModal'


const Header: React.FC = () => {
  const { isMobile } = useDevice()
  const router = useRouter()
  const [ showLogo, setShowLogo ] = useState(router.pathname !== '/')
  const [ withBg, setWithBg ] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 10) {
        setWithBg(true)
      }
      else {
        setWithBg(false)
      }

      if (router.pathname !== '/' || document.documentElement.scrollTop > document.documentElement.clientHeight/2) {
        setShowLogo(true)
      }
      else {
        setShowLogo(false)
      }
    }

    router.pathname !== '/' ? setShowLogo(true) : setShowLogo(false)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ router.pathname ])

  return (
    <header className={cx(s.header, withBg && s.withBg)}>
      <WrongNetworkBanner />
      <ConnectWalletModal />
      <CreateAccountModal />
      <div className={s.inner}>
        <WidthContainer>
          {isMobile ? <Mobile showLogo={showLogo} /> : <Desktop showLogo={showLogo} />}
        </WidthContainer>
      </div>
    </header>
  )
}

export default Header
