import React from 'react'
import { useConnect, constants as web3constands } from 'web3'
import { requestMetamaskToChangeNetwork } from 'chain-id'
import cx from 'classnames'

import { WidthContainer } from 'components/layout'
import { Text } from 'components/dataDisplay'

import s from './WrongNetworkBanner.module.scss'
import { constants } from 'helpers'


const WrongNetworkBanner = () => {
  const { isActive, chainId, isSupportedNetwork } = useConnect()

  if (isActive && isSupportedNetwork) {
    return null
  }

  if (!chainId) {
    return null
  }

  const chainName = constants.chainNames[web3constands.preferredChainId]

  return (
    <div className={cx(s.wrongNetwork, 'cursor-pointer text-center')} onClick={() => requestMetamaskToChangeNetwork(web3constands.preferredChainId)}>
      <Text
        message={`Wrong network. Looks like you connected to unsupported network. <span class="color-white">Change network</span> to ${chainName}`}
        style="c16"
        color="gray-100"
        tag="span"
        html
      />
    </div>
  )
}


export default React.memo(WrongNetworkBanner)
