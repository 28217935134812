import Head from 'next/head'
import React, { FC } from 'react'


interface MetaOnPageProps {
  title?: string,
  description?: string,
  imageUrl?: string
}
const PageMeta: FC<MetaOnPageProps> = ({ title, description, imageUrl }) => {
  const titleText = `${title} | Babylon 7`
  const resultTitle = (
    <title>
      {titleText}
    </title>
  )

  return (
    <Head>
      {title && resultTitle}
      {title && <meta key="title" property="og:title" content={titleText} />}
      {title && <meta key="twitterTitle" name="twitter:title" content={titleText} />}
      {description && <meta key="description" name="description" content={description} />}
      {description && <meta key="ogDescription" property="og:description" content={description} />}
      {description && <meta key="twitterDescription" name="twitter:description" content={description} />}
      {imageUrl && <meta key="ogImage" property="og:image" content={imageUrl} />}
      {imageUrl && <meta key="twitterImage" name="twitter:image" content={imageUrl} />}
    </Head>
  )
}

export default PageMeta
