import { Icon, IconName } from 'components/ui'
import React, { FC } from 'react'
import s from './MenuButton.module.scss'
import { Text } from 'components/dataDisplay'
import Link from 'next/link'


type MenuButtonProps = {
  icon: IconName
  title: string
  to?: string
  onClick?: () => void
}

const MenuButton: FC<MenuButtonProps> = ({ icon, title, to, onClick }) => {
  return (
    <>
      {
        to ? (
          <Link href={to}>
            <div className={s.btn} onClick={onClick} role="button">
              <Icon className="mr-8" name={icon} size={14} />
              <Text className="uppercase" style="c12" message={title} />
            </div>
          </Link>
        ) : (
          <div className={s.btn} onClick={onClick}>
            <Icon className="mr-8" name={icon} size={14} />
            <Text className="uppercase" style="c12" message={title} />
          </div>
        )
      }
    </>
  )


}

export default MenuButton
