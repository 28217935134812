import React, { FC } from 'react'
import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import s from './Created.module.scss'


const Created: FC< { website: string } > = ({ website }) => {
  return (
    <>
      <img className={s.img} src="/images/logos/bg-logo.webp" alt="b7" />
      <Text className="mt-24" style="h6" html message="Profile has been <br /> successfully created" />
      <Text
        className="mt-8"
        style="c14"
        html
        message="Thank you for registering. A verification email has been sent to your email address. Please confirm it to get full access to the site."
        color="gray-60"
      />
      <Button
        className="mt-24"
        title="open email"
        size={40}
        toTab={`https://${website}`}
        target="_blank"
        fullWidth
      />
    </>
  )
}

export default Created
