export default {
  origin: process.env.NEXT_PUBLIC_URL,
  secondsInDay: 86_400,
  listingTimeout: 604_800,
  localStorage: {
    connectorName: 'babylon7-connector-name',
    token: 'babylon7-jwt-token',
    grid: 'babylon7-nft-grid',
    policyConcent: 'babylon7-policy-content',
    lengthWarning: 'babylon7-length-warning',
    skippedRegistration: 'babylon7-skipped-registration',
    visited: 'babylon7-already-visited',
    listingWarning: 'babylon7-plisting-warning',
  },
  nullAddress: '0x0000000000000000000000000000000000000000',
  chainNames: {
    1: 'Ethereum',
    5: 'Görli',
    11155111: 'Sepolia',
  },
  operatorFilters: {
    1: '0x1dE06D2875453a272628BbB957077d18eb4A84CD',
    5: '0x851b63Bf5f575eA68A84baa5Ff9174172E4d7838',
    11155111: '0x0000000000000000000000000000000000000000',
  },
  urls: {
    docs: 'https://docs.babylon.art/',
    privacyPolicy: 'https://docs.babylon.art/babylon-7/the-fine-print/privacy-policy',
    termsOfService: 'https://docs.babylon.art/babylon-7/',
    support: 'https://twitter.com/Babylon_Support',
    medium: 'https://medium.babylon.art',
    twitter: 'https://twitter.com/0xBabylon',
    instagram: 'https://www.instagram.com/0xbabylon.art/',
    getVerifiedForm: 'https://maufxc0zezm.typeform.com/babylon-verify',
    whitelistNFTForm: 'https://maufxc0zezm.typeform.com/to/EIqVPJsJ',
    gitbook: 'https://docs.babylon.art/babylon-7/',
    designBy: 'https://flatstudio.co',
    faq: 'https://docs.babylon.art/babylon-7/collectors/faq',
  },
}
