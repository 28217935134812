import React, { useMemo, useCallback } from 'react'
import { modalVisibility, openModal, closeModal as closeModalByName } from '@locmod/modal'
import type { ModalVisibilityProps } from '@locmod/modal'

import { PlainModal } from 'components/feedback'
import type { DropdownProps } from '../../Dropdown'

import s from './Mobile.module.scss'


const DropdownModal: React.FC<ModalVisibilityProps & { mobileClosable?: boolean }> = (props) => {
  const { mobileClosable = false, closeModal, children } = props

  return (
    <PlainModal
      withCloseButton={mobileClosable}
      closeModal={closeModal}
      className={s.modal}
      contentClassName={s.content}
    >
      {children}
    </PlainModal>
  )
}

const Mobile: React.FC<DropdownProps> = ({ children, name, content, className, mobileClosable }) => {
  const Modal = modalVisibility(name, DropdownModal) as React.FC
  const openDropdownModal = () => openModal(name, { mobileClosable })

  const child = useMemo(() => {
    if (typeof children === 'function') {
      return children({ isOpen: false })
    }

    return (
      <div className="flex items-center">
        {children}
      </div>
    )
  }, [ children ])

  const closeModal = useCallback(() => {
    closeModalByName(name)
  }, [])

  return (
    <>
      {
        React.createElement('div', {
          onClick: content && openDropdownModal,
          className,
        }, child)
      }
      <Modal>
        {
          React.cloneElement(content, {
            closeModal,
          })
        }
      </Modal>
    </>
  )
}

export default Mobile
