import ConnectButton from 'compositions/PageLayout/components/ConnectButton/ConnectButton'
import React from 'react'
import { useConnect } from 'web3'
import s from './Desctop.module.scss'

import { AccountButton, Logo, NotificationsButton, Navbar } from '../../components'
import { ConnectingContext } from 'local_modules/web3/Connector'
import { Bone } from 'components/feedback'
import ScrambledText from 'components/dataDisplay/ScrambledText/ScrambledText'


const ExceptMobile: React.FC<{showLogo: boolean}> = ({ showLogo }) => {
  const { account } = useConnect()
  const isConnecting = React.useContext(ConnectingContext)

  return (
    <div className="flex items-center justify-between relative">
      <ScrambledText style="glitch18" message="Rebuilding gallery . . ." time={200} />
      {
        showLogo && (
          <div className={s.logo}>
            <Logo />
          </div>
        )
      }
      <div className="flex items-center gap-16">
        <Navbar />
        {
          isConnecting ? <Bone w={202} h={48} rounded /> : account ? (
            <>
              <NotificationsButton />
              <AccountButton />
            </>
          ) : (
            <ConnectButton size={48} />
          )
        }
      </div>
    </div>
  )
}

export default ExceptMobile
