import React, { useEffect, useState } from 'react'
import type { Field } from 'formular'
import { useFieldState } from 'formular'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'
import { Icon } from 'components/ui'

import s from './Select.module.scss'


export type SelectOption = {
  title: string
  value: string
}

export type SelectProps = {
  className?: string
  field: Field<string>
  placeholder?: string
  options: SelectOption[]
  loading?: boolean
  disabled?: boolean
  captionLeft?: string
  subNode?: React.ReactElement
  onChange?: (value: string) => void
  dataTestId?: string
}

const Select: React.FunctionComponent<SelectProps> = (props) => {
  const {
    className, field, placeholder, options, loading, disabled,
    captionLeft, subNode,
    onChange, dataTestId,
  } = props

  const [ isOpen, setIsOpen ] = useState(false)
  const { value, error } = useFieldState<string>(field)

  const handleChange = (value: string) => {
    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const isFilled = value !== '' && value !== null
  const isErrored = Boolean(error)

  const rootClassName = cx(s.root, s[`size-${60}`], className)

  const selectClassName = cx(s.select, {
    [s.filled]: isFilled,
    [s.errored]: isErrored,
    [s.disabled]: loading || disabled,
    [s.focus]: isOpen,
  })

  const Dropdown = () => {
    return (
      <div className={s.dropdown}>
        {
          options.map(({ title, value }) => (
            <div
              key={value}
              className={s.option}
              onClick={
                () => handleChange(value)
              }
            >
              <Text style="c12" message={title} />
            </div>
          ))
        }
      </div>
    )
  }

  const initText = placeholder

  useEffect(() => {
    const handleClickOutside = () => setIsOpen(false)

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleContainerClick = (event) => {
    if (!disabled) {
      setIsOpen(prev => !prev)
    }
    event.stopPropagation()
  }

  return (
    <div className={rootClassName} onClick={handleContainerClick}>
      <div className={cx(s.arrow, isOpen && s.opened)}>
        <Icon name={loading ? 'interface/spinner' : 'interface/chevron-down'} size={20} />
      </div>
      <div
        className={selectClassName}
        data-testid={dataTestId}
      >
        <Text style="c12" message={options?.find(opt => opt.value === value)?.title || initText} />
      </div>
      {
        isOpen && (
          <Dropdown />
        )
      }
      {
        (captionLeft || subNode || isErrored) && (
          <div className="mt-4 pl-4">
            {
              captionLeft && !isErrored && (
                <Text message={captionLeft} style="c16" />
              )
            }
            {
              subNode && !isErrored && (
                subNode
              )
            }
            {
              isErrored && (
                <Text message={error} style="c16" color="accent-red-50" />
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default React.memo(Select)
