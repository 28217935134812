import React from 'react'
import { Avatar } from 'components/dataDisplay'
import { useProfile } from 'context'
import { useConnect } from 'web3'
import cx from 'classnames'
import { Dropdown } from 'components/inputs'
import AccountDropdown from 'compositions/PageLayout/components/AccountDropdown/AccountDropdown'
import s from './AccountButton.module.scss'


const Profile: React.FC = () => {
  const { account } = useConnect()
  const { profile } = useProfile()

  return (
    <Dropdown
      name="HeaderProfile"
      content={<AccountDropdown />}
      placement="bottomRight"
    >
      {
        ({ isOpen }) => {
          return (
            <div className={cx(s.container, isOpen && s.open)}>
              <Avatar
                address={account}
                size={40}
                src={profile?.avatar}
              />
            </div>
          )
        }
      }
    </Dropdown>
  )
}

export default Profile
