import React, { FC, useState } from 'react'
import Text from '../Text/Text'
import cx from 'classnames'
import s from './NFTCard.module.scss'
import ImageFallback from '../ImageFallback/ImageFallback'
import GlitchImage from '../GlitchImage/GlitchImage'
import { useDevice } from 'device'


type NFTCardProps = {
  nft: NFT
}

const NFTCard: FC<NFTCardProps> = ({ nft }) => {
  const { title, mediaUrl, contractName } = nft || {}
  const [ isHovered, setIsHovered ] = useState(false)
  const { isMobile } = useDevice()

  return (
    <div className={cx(s.container)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className={cx(s.mediaContainer)}>
        {
          isHovered && !isMobile ? (
            <GlitchImage
              loading="lazy"
              className={cx(s.media)}
              src={mediaUrl}
              alt={title}
            />
          ) : (
            <ImageFallback
              loading="lazy"
              className={cx(s.media)}
              src={mediaUrl}
              alt={title}
            />
          )
        }
      </div>
      <div className={cx('py-16', isMobile ? 'px-16' :'px-24')}>
        <Text style="c16" color="gray-100" tag="p" message={contractName} />
        <Text
          className="mt-4"
          style="c16"
          color="gray-40"
          message={title}
          tag="p"
        />
      </div>
    </div>
  )
}

export default NFTCard
