import { constants, restAPI } from 'helpers'
import { useQuery } from 'hooks'


type ProfileResult = {
  data: Profile | null
  error: boolean
  error_message: string
}

type FetchUserProps = {
  address: string
}

const useFetchUser = ({ address }: FetchUserProps) => {
  const isNullAddress = address === constants.nullAddress
  const endpoint = `/user?address=${address}`

  const fetcher = async () => {
    try {
      const { data } = await restAPI.get<ProfileResult>(endpoint)

      return data
    }
    catch (error) {
      return null
    }
  }

  const { isFetching, data } = useQuery<ProfileResult>({
    endpoint,
    fetcher,
    skip: !address || isNullAddress,
  })

  return {
    isFetching,
    data: data?.data,
  }
}

export default useFetchUser
